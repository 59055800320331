const Plyr = require('plyr');
const Cookies = require('js-cookie');
import loadcss from 'loadcss';

document.addEventListener("DOMContentLoaded", (event) => {
  
  // leave the eventhandler if no audio files
  if (!document.querySelector('audio.audio-player')) {
    return;
  }

  const player = new Plyr("audio.audio-player", {});
  const pId = player.media.getAttribute('data-id');
  let playstate = Cookies.get(`playstate-${pId}`);
  
  player.on("ready", function () {
    // NOTE: window.STATIC_URL should be defined in base template.
    loadcss(window.STATIC_URL + 'plyr/dist/plyr.css');
    canSeek = true;

  })

  player.on("canplay", () => {
    if(canSeek && playstate) {
      player.currentTime = parseFloat(playstate);
      canSeek = false;
    }
  });
    
  window.addEventListener("beforeunload", () => {
    Cookies.set(`playstate-${pId}`, player.currentTime, { expires: 1200 });
  });

});
